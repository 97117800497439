export enum EducationLevel {
    CAP_BEP = "CAP_BEP",
    BAC = "BAC",
    BAC2 = "BAC2",
    BAC3 = "BAC3",
    BAC4 = "BAC4",
    BAC5 = "BAC5",
    BAC6 = "BAC6",
    PHD = "PHD",
    POSTDOC = "POSTDOC",
}

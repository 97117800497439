import React from 'react';
import { useIntl } from 'react-intl';

const NotFoundPage: React.FC = () => {
    const intl = useIntl();

    return (
        <div className="h-screen flex flex-col items-center justify-center text-center">
            <h1 className="text-4xl font-bold text-gray-800">😭 404 😭 </h1>
            <p className="text-lg text-gray-600 mt-4 mb-8">{intl.formatMessage({ id: "notFound.title", defaultMessage: "Oops! The page you're looking for doesn't exist." })}</p>
            <button
                onClick={() => window.history.back()}
                className="px-6 py-3 bg-gray text-white rounded-xl cursor-pointer transition duration-300">
                {intl.formatMessage({ id: "global.back", defaultMessage: "Go Back" })}
            </button>
        </div>
    );
};

export default NotFoundPage;

import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import ResumeForm from './resume-form';

type CandidateRequestModel = {
    name: string;
    familyName: string;
    contact: string;
    // cvCoverName: string;
    // cvCoverInBase64: string;
    cvFileName: string;
    cvInBase64: string;
    city: string;
    state: string,
    province: string,
    address: string,
    fullAddress: string;
    postalCode: string;
    telephoneNumber: string;
    birthDate: string;
    studyLevel: string;
    jobTitle: string;
    yearsOfExperience: string;
    currentPosition: string;
    currentWorkingArea: string;
};

type JobApplicationRequestModel = {
    creationDateTime: string;
    jobRef: string;
    jobTitle: string;
    boardCode: string;
    candidate: CandidateRequestModel;
};

interface JobApplicationProps {
    jobTitle: string;
    color: string;
    radius: string;
    privacyPolicyLinks: string[];
    positionId: string;
    companyId: string;
    jobId: string;
    boardCode: string;
}

const JobApplication: React.FC<JobApplicationProps> = ({
    jobTitle,
    privacyPolicyLinks,
    companyId,
    positionId,
    jobId,
    radius,
    color,
    boardCode }) => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const customerId = params.get('customerId') || '';
    const intl = useIntl();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [thankYouMessage, setThankYouMessage] = useState('');

    const handleSubmit = async (formData: any) => {
        setLoading(true);

        const creationDateTime = new Date().toISOString();

        const candidate: CandidateRequestModel = {
            name: formData.name.split(' ')[0],
            familyName: formData.familyName,
            contact: formData.email,
            cvFileName: formData.resumeFile ? formData.resumeFile.name : "",
            cvInBase64: formData.resume ? formData.resume.toString() : "",
            telephoneNumber: formData.phone,
            // cvCoverName: "",
            // cvCoverInBase64: "",
            city: formData.city,
            state: formData.state,
            province: formData.province,
            address: formData.address,
            fullAddress: formData.fullAddress,
            postalCode: formData.postalCode,
            birthDate: formData.birthDate,
            yearsOfExperience: formData.yearsOfExperience,
            studyLevel: formData.studyLevel,
            jobTitle: jobTitle,
            currentPosition: formData.currentPosition,
            currentWorkingArea: formData.currentWorkingArea,
        };

        const apiUrl = process.env.REACT_APP_API_URL + "/postCandidateApplicants";
        const apiKey = process.env.REACT_APP_GET_JOBPOSTS_SERVER_API_KEY;

        const jobApplicationRequest: JobApplicationRequestModel = {
            jobRef: companyId + "__" + positionId + "__" + jobId,
            jobTitle: jobTitle,
            creationDateTime: creationDateTime,
            boardCode: boardCode,
            candidate: candidate
        };

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiKey}`
                },
                body: JSON.stringify(jobApplicationRequest)
            });

            if (response.ok) {
                const thankYouMessage = intl.formatMessage({ id: "careerPageApply.successMessage", defaultMessage: "Resume updated successfully!" });
                navigate('/thank-you', { state: { message: thankYouMessage } });
            } else {
                alert(intl.formatMessage({ id: "careerPageApply.errorMessage", defaultMessage: "Failed to update resume. Please try again later." }));
            }
        } catch (error) {
            console.error('Error submitting application:', error);
            alert('Failed to submit application. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <ResumeForm
            onSubmit={handleSubmit}
            loading={loading}
            setLoading={setLoading}
            privacyPolicyLinks={privacyPolicyLinks}
            color={color}
            radius={radius}
            formTitle={intl.formatMessage({ id: "careerPageApply.applyForJob", defaultMessage: "Apply for Job" })}
            buttonText={intl.formatMessage({ id: "careerPageApply.submit", defaultMessage: "Submit" })}
        />
    );
};

export default JobApplication;

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import ResumeForm from '../components/career-page/resume-form';
import { ApplicantFormData } from '../utils/interfaces';

interface CompanyBrandAndInfo {
    companyName: string;
    borderRadius: string;
    color: string;
    logo: string;
    viewType: string;
    privacyPolicies: string[];
}

const UpdateResumePage: React.FC = () => {
    const { customerId, accessToken: accessToken } = useParams<{ customerId: string; accessToken: string }>();
    const [companySettings, setCompanySettings] = useState<CompanyBrandAndInfo | null>(null);
    const [loading, setLoading] = useState(false);
    const intl = useIntl();
    const navigate = useNavigate();

    const getCompanyBrandAndInfoApiUrl = process.env.REACT_APP_API_URL + "/getCompanyData";
    const postApplicantResumeApiUrl = process.env.REACT_APP_API_URL + "/applicantResumeUpdate";
    const apiKey = process.env.REACT_APP_GET_JOBPOSTS_SERVER_API_KEY;

    useEffect(() => {
        const fetchCompanySettings = async () => {
            try {
                const response = await fetch(`${getCompanyBrandAndInfoApiUrl}?companyId=${customerId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${apiKey}`,
                    },
                });
                const fetchedSettings = await response.json();

                const settings: CompanyBrandAndInfo = {
                    color: fetchedSettings.color,
                    borderRadius: fetchedSettings.borderRadius,
                    viewType: fetchedSettings.viewType,
                    logo: fetchedSettings.logo,
                    privacyPolicies: fetchedSettings.privacyPolicies,
                    companyName: fetchedSettings.companyName
                };

                setCompanySettings(settings);
            } catch (error) {
                console.error('Error fetching Company settings:', error);
            }
        };

        fetchCompanySettings();
    }, [customerId, accessToken]);

    if (!companySettings) {
        return <div className="flex justify-center items-center h-screen">{intl.formatMessage({ id: "global.loading", defaultMessage: "Loading..." })}</div>;
    }

    const handleSubmit = async (formData: ApplicantFormData) => {
        setLoading(true);

        const resumeUpdateRequest = {
            accessToken: accessToken,
            customerId,
            additionalData: {
                resume: formData.resume ? formData.resume.toString() : "",
                name: formData.name,
                familyName: formData.familyName,
                email: formData.email,
                phone: formData.phone,
                fullAddress: formData.fullAddress,
                state: formData.state,
                postalCode: formData.postalCode,
                city: formData.city,
                province: formData.province,
                address: formData.address,
                birthDate: formData.birthDate,
                studyLevel: formData.studyLevel,
                currentPosition: formData.currentPosition,
                yearsOfExperience: formData.yearsOfExperience,
                currentWorkingArea: formData.currentWorkingArea,
                privacyConsent: formData.privacyConsent
            }
        };

        try {
            const response = await fetch(postApplicantResumeApiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${apiKey}`,
                },
                body: JSON.stringify(resumeUpdateRequest),
            });

            if (response.ok) {
                const thankYouMessage = intl.formatMessage({ id: "updateResume.successMessage", defaultMessage: "Resume updated successfully!" });
                navigate('/thank-you', { state: { message: thankYouMessage } });
            } else {
                alert(intl.formatMessage({ id: "updateResume.errorMessage", defaultMessage: "Failed to update resume. Please try again later." }));
            }
        } catch (error) {
            console.error('Error updating resume:', error);
            alert('Failed to update resume. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container mx-auto">
            <div className="flex flex-col gap-8 px-4 py-8">
                <div>
                    <div className="w-full flex flex-col justify-between items-center ">
                        {companySettings.logo && (
                            <img
                                src={`data:image/png;base64,${companySettings.logo}`}
                                alt="Company Logo"
                                className="h-32 w-auto pr-6 mb-8"
                            />
                        )}
                        <div className="p-4 rounded-lg flex items-center justify-between mb-4 max-w-[700px]"
                            style={{
                                backgroundColor: `#${companySettings.color}33`,
                                borderRadius: companySettings.borderRadius
                            }}>
                            <h3 className="mb-4 font-light text-center">{companySettings.companyName + " " + intl.formatMessage({
                                id: "updateResume.description", defaultMessage: "Requires you to upload your new resume to update your information in the system. Keep your data fresh to be contacted for new opportunities and have better chances."
                            })}</h3>
                        </div>
                    </div>
                </div>

                <div>
                    <ResumeForm
                        onSubmit={handleSubmit}
                        loading={loading}
                        setLoading={setLoading}
                        privacyPolicyLinks={companySettings.privacyPolicies}
                        color={companySettings.color}
                        radius={companySettings.borderRadius}
                        formTitle={intl.formatMessage({ id: "updateResume.title", defaultMessage: "Update Resume" })}
                        buttonText={intl.formatMessage({ id: "updateResume.submit", defaultMessage: "Submit" })}
                        showOnlyResumeDrop={true}
                    />
                </div>
            </div>
        </div>
    );
};

export default UpdateResumePage;
